import React, { useState, useEffect } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import css from "@styled-system/css"
import { motion } from "framer-motion"

import { SubHeaderFade } from "animations"
import { Box, Flex, Footer, Heading, PageHeader, SEO } from "components"
import { useStickyState } from "utils"

import Columns from "./_columns"

const CloseSVG = ({ offset }) => (
  <Box
    as="svg"
    width={[24, 24, 42]}
    height={[24, 24, 42]}
    mt={[0, 0, 10]}
    viewBox="0 0 42 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      display: "inline-block",
      verticalAlign: "top",
      marginRight: 32,
      marginLeft: offset ? -74 : 0,
    }}
  >
    <path d="M41 1L21 21M1 41L21 21M21 21L41 41M21 21L1 1" stroke="black" />
  </Box>
)

const Filtering = ({
  categories,
  activeParentCategory,
  setActiveParentCategory,
  activeCategory,
  setActiveCategory,
  subcategories,
  setActiveSubcategories,
}) => (
  <Heading
    position="relative"
    size={900}
    fontFamily="serif"
    fontWeight={300}
    css={css({
      "> span": {
        display: "block",
        height: ["32px", null, "64px"],
        "> span": {
          display: "inline-block",
          height: ["32px", null, "64px"],
          cursor: "pointer",
          ":hover": {
            mt: "-0.2em",
            fontFamily: "sans",
            fontWeight: 700,
            fontStyle: "italic",
          },
          '& [data-selected="true"]': {
            mt: "-0.2em",
            fontFamily: "sans",
            fontWeight: 700,
          },
        },
      },
    })}
  >
    {categories &&
      categories.map(({ category }, index) => {
        // Filter categories by ones with proper names, and filter out all except the active category when a subcategory is active
        if (
          (category &&
            category.document &&
            category.document[0] &&
            category.document[0].data.name &&
            !subcategories) ||
          (category &&
            category.document &&
            category.document[0] &&
            category.document[0].data.name &&
            activeParentCategory === category.document[0].data.name.text)
        ) {
          return (
            <motion.span
              variants={SubHeaderFade(subcategories ? 0.3 : 0.7 + index * 0.1)}
              initial="initial"
              animate="visible"
              exit="exit"
              key={
                (subcategories ? "subcategoryKeyBreak" : " ") +
                "category" +
                index
              }
            >
              <span
                onClick={() => {
                  if (
                    activeCategory !== category.document[0].data.name.text &&
                    !subcategories
                  ) {
                    // Set active category
                    setActiveCategory(category.document[0].data.name.text)
                    // Set subcategories if applicable
                    if (
                      category.document[0].data.subcategories &&
                      category.document[0].data.subcategories[0] &&
                      category.document[0].data.subcategories[0].subcategory &&
                      category.document[0].data.subcategories[0].subcategory
                        .document
                    ) {
                      setActiveParentCategory(
                        category.document[0].data.name.text
                      )
                      setActiveSubcategories(
                        category.document[0].data.subcategories
                      )
                    } else {
                      setActiveParentCategory(undefined)
                      setActiveSubcategories(undefined)
                    }
                  } else {
                    setActiveCategory("All")
                    setActiveParentCategory(undefined)
                    setActiveSubcategories(undefined)
                  }
                }}
              >
                {activeParentCategory ===
                  category.document[0].data.name.text && (
                  <CloseSVG offset={false} />
                )}
                {activeParentCategory !==
                  category.document[0].data.name.text && (
                  <>
                    <span
                      data-selected={
                        activeCategory === category.document[0].data.name.text
                          ? "true"
                          : ""
                      }
                    >
                      {category.document[0].data.name.text}
                    </span>
                    {index !== categories.length - 1 ? "," : ""}
                  </>
                )}
              </span>
            </motion.span>
          )
        } else {
          return null
        }
      })}
    {subcategories && (
      <>
        {subcategories.map(({ subcategory }, index) => {
          if (
            subcategory &&
            subcategory.document &&
            subcategory.document[0] &&
            subcategory.document[0].data.name
          ) {
            return (
              <motion.span
                variants={SubHeaderFade(0.3 + index * 0.1)}
                initial="initial"
                animate="visible"
                exit="exit"
                key={"subcategory" + index}
              >
                <span
                  onClick={() => {
                    if (
                      activeCategory !== subcategory.document[0].data.name.text
                    ) {
                      setActiveCategory(subcategory.document[0].data.name.text)
                    } else {
                      setActiveCategory(activeParentCategory)
                    }
                  }}
                >
                  <span
                    data-selected={
                      activeCategory === subcategory.document[0].data.name.text
                        ? "true"
                        : ""
                    }
                  >
                    {subcategory.document[0].data.name.text}
                  </span>
                  {index !== subcategories.length - 1 ? "," : ""}
                </span>
              </motion.span>
            )
          } else {
            return null
          }
        })}
      </>
    )}
  </Heading>
)

function IndexPage({ isMobile, preview }) {
  // Data & filtering management
  const { page, categories } = useStaticQuery(PAGE_QUERY)
  const [homePage, setHomePage] = useState(() => {
    if (page.dataString) {
      let dataParsed = JSON.parse(page.dataString)
      return {
        seo: {
          title: dataParsed.seo_title && dataParsed.seo_title[0].text,
          description:
            dataParsed.seo_description && dataParsed.seo_description[0].text,
          image: dataParsed.seo_image && dataParsed.seo_image.url,
          imageAlt: dataParsed.seo_image && dataParsed.seo_image.alt,
        },
        closingTitle:
          dataParsed.closing_title && dataParsed.closing_title[0].text,
      }
    }
  })
  const [activeParentCategory, setActiveParentCategory] = useState(undefined)
  const [activeCategory, setActiveCategory] = useStickyState(
    "All",
    "activeCategory"
  )
  const [subcategories, setActiveSubcategories] = useState(undefined)
  // Column settings
  const COLUMN_SPEED = 1600
  const [activeColumns, setActiveColumns] = useState(undefined)
  const [paddingBottom, setPaddingBottom] = useState({ mobile: 0, desktop: 0 })

  // On Active Category change, create columns and filter projects
  useEffect(() => {
    if (activeCategory) {
      let sortedColumns = {
        desktop: [
          { height: 0, projects: [], offset: COLUMN_SPEED },
          { height: 0, projects: [], offset: COLUMN_SPEED / 2 },
          { height: 0, projects: [], offset: 0 },
          { height: 0, projects: [], offset: (COLUMN_SPEED * 4) / 5 },
        ],
        mobile: [
          { height: 0, projects: [], offset: 0 },
          { height: 0, projects: [], offset: COLUMN_SPEED / 2 },
        ],
      }
      categories.nodes.forEach(category => {
        let categoryParsed = JSON.parse(category.dataString)
        if (preview && category.prismicId === preview.id) {
          categoryParsed = preview.data
        }
        if (
          categoryParsed.name &&
          categoryParsed.name[0] &&
          categoryParsed.name[0].text === activeCategory &&
          categoryParsed.featured_projects
        ) {
          let width =
            (window.innerWidth ||
              document.documentElement.clientWidth ||
              document.body.clientWidth) - 96
          // Sort projects into columns, evening out based on height
          let activeColumn = 0
          let activeColumnMobile = 0
          categoryParsed.featured_projects.forEach((project, index) => {
            if (project.image && project.image.url) {
              // FIRST 10-- PRESERVE ORDER
              if (index < 10) {
                sortedColumns.desktop[activeColumn].height +=
                  ((project.image.dimensions.height /
                    project.image.dimensions.width) *
                    width) /
                  4
                sortedColumns.desktop[activeColumn].projects.push(project)

                // MOBILE
                sortedColumns.mobile[activeColumnMobile].height +=
                  ((project.image.dimensions.height /
                    project.image.dimensions.width) *
                    width) /
                  2
                sortedColumns.mobile[activeColumnMobile].projects.push(project)
                activeColumn++
                if (activeColumn > 3) {
                  activeColumn = 0
                }
                activeColumnMobile++
                if (activeColumnMobile > 1) {
                  activeColumnMobile = 0
                }
              } else {
                // DESKTOP
                let shortestColumn = 0
                sortedColumns.desktop.forEach((column, index) => {
                  if (
                    column.height - column.offset <
                    sortedColumns.desktop[shortestColumn].height -
                      sortedColumns.desktop[shortestColumn].offset
                  ) {
                    shortestColumn = index
                  }
                })
                sortedColumns.desktop[shortestColumn].height +=
                  ((project.image.dimensions.height /
                    project.image.dimensions.width) *
                    width) /
                  4
                sortedColumns.desktop[shortestColumn].projects.push(project)

                // MOBILE
                let shortestColumnMobile = 0
                sortedColumns.mobile.forEach((column, index) => {
                  if (
                    column.height - column.offset <
                    sortedColumns.mobile[shortestColumnMobile].height -
                      sortedColumns.mobile[shortestColumnMobile].offset
                  ) {
                    shortestColumnMobile = index
                  }
                })
                sortedColumns.mobile[shortestColumnMobile].height +=
                  ((project.image.dimensions.height /
                    project.image.dimensions.width) *
                    width) /
                  2
                sortedColumns.mobile[shortestColumnMobile].projects.push(
                  project
                )
              }
            }
          })
        }
      })
      // Set a padding on the bottom of the page to offset for potential hiccups
      let paddingBottom = 0
      sortedColumns.desktop.forEach((column, index) => {
        if (
          column.height - column.offset > sortedColumns.desktop[2].height &&
          index !== 2
        ) {
          let calc =
            column.height - column.offset - sortedColumns.desktop[2].height
          if (calc > paddingBottom) {
            paddingBottom = calc
          }
        }
      })
      let paddingBottomMobile = 0
      sortedColumns.mobile.forEach((column, index) => {
        if (
          column.height - column.offset > sortedColumns.mobile[0].height &&
          index !== 0
        ) {
          let calc =
            column.height - column.offset - sortedColumns.mobile[0].height
          if (calc > paddingBottomMobile) {
            paddingBottomMobile = calc
          }
        }
      })
      setPaddingBottom({
        mobile: paddingBottomMobile,
        desktop: paddingBottom,
      })
      setActiveColumns(sortedColumns)
    }
  }, [activeCategory])

  // Parse and create page data based on either static query or preview API on Page Load
  useEffect(() => {
    // Setup intial subcategories if relevant
    if (activeCategory !== "All") {
      categories.nodes.forEach(category => {
        if (
          category &&
          category.data.name &&
          activeCategory === category.data.name.text
        ) {
          // Set subcategories if applicable
          if (
            category.data.subcategories &&
            category.data.subcategories[0] &&
            category.data.subcategories[0].subcategory &&
            category.data.subcategories[0].subcategory.document
          ) {
            setActiveParentCategory(category.data.name.text)
            setActiveSubcategories(category.data.subcategories)
          } else {
            setActiveParentCategory(undefined)
            setActiveSubcategories(undefined)
          }
        }
      })
    }
  }, [])

  // load category from string if relevant
  useEffect(() => {
    if (window.location.search) {
      let searchTerm = window.location.search.replace("?category=", "")

      categories.nodes.forEach(category => {
        if (
          category &&
          category.data.name &&
          searchTerm ===
            category.data.name.text.toLowerCase().replace(/[\W_]+/g, "-")
        ) {
          setActiveCategory(category.data.name.text)
          // Set subcategories if applicable
          if (
            category.data.subcategories &&
            category.data.subcategories[0] &&
            category.data.subcategories[0].subcategory &&
            category.data.subcategories[0].subcategory.document
          ) {
            setActiveParentCategory(category.data.name.text)
            setActiveSubcategories(category.data.subcategories)
          } else {
            setActiveParentCategory(undefined)
            setActiveSubcategories(undefined)
          }
        }
        category.data.subcategories.forEach(({ subcategory }) => {
          if (
            subcategory &&
            subcategory.document &&
            subcategory.document[0] &&
            subcategory.document[0].data.name.text
              .toLowerCase()
              .replace(/[\W_]+/g, "-") === searchTerm
          ) {
            setActiveCategory(subcategory.document[0].data.name.text)
            setActiveParentCategory(category.data.name.text)
            setActiveSubcategories(category.data.subcategories)
          }
        })
      })
    }
  }, [])

  return (
    <>
      {homePage && homePage.seo && (
        <SEO
          title={homePage.seo && homePage.seo.title}
          description={homePage.seo && homePage.seo.description}
          image={homePage.seo && homePage.seo.image}
          imageAlt={homePage.seo && homePage.seo.imageAlt}
        />
      )}

      <PageHeader
        workClick={() => {
          setActiveParentCategory(undefined)
          setActiveSubcategories(undefined)
          setActiveCategory("All")
        }}
      >
        <Filtering
          activeParentCategory={activeParentCategory}
          setActiveParentCategory={setActiveParentCategory}
          activeCategory={activeCategory}
          setActiveCategory={setActiveCategory}
          categories={page.data.categories}
          subcategories={subcategories}
          setActiveSubcategories={setActiveSubcategories}
        />
      </PageHeader>

      <Box as="main" minHeight="100vh" overflow="hidden" pb="50vh">
        <Box>
          <Flex
            flexWrap="wrap"
            css={css({
              m: [-3, -5],
              "> *": {
                width:
                  activeCategory === "Motion"
                    ? ["50%", "50%", "50%"]
                    : ["50%", "50%", "25%"],
                p: [3, 5],
              },
            })}
          >
            {activeCategory && activeColumns && (
              <Columns
                COLUMN_SPEED={COLUMN_SPEED}
                paddingBottom={paddingBottom}
                activeColumns={activeColumns}
                activeCategory={activeCategory}
                isMobileProp={isMobile}
              />
            )}
          </Flex>
        </Box>
      </Box>
      {/* ================== CLOSING CALL TO ACTION ================== */}
      {homePage.closingTitle && (
        <Box textAlign="center" pb="33vh">
          <Heading as={Link} to="/contact/" size={800} fontFamily="serif">
            {homePage.closingTitle}
          </Heading>
        </Box>
      )}
      <Footer />
    </>
  )
}

// GraphQL query for this page
const PAGE_QUERY = graphql`
  {
    page: prismicHomePage {
      dataString
      data {
        categories {
          category {
            document {
              data {
                name {
                  text
                }
                subcategories {
                  subcategory {
                    document {
                      data {
                        name {
                          text
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    categories: allPrismicCategory {
      nodes {
        prismicId
        dataString
        data {
          name {
            text
          }
          subcategories {
            subcategory {
              document {
                data {
                  name {
                    text
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default IndexPage

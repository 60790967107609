import React from "react"
import { Link } from "gatsby"
import {
  AnimatePresence,
  motion,
  useViewportScroll,
  useTransform,
} from "framer-motion"

import { Column1, Column2, Column3, Column4 } from "animations"
import { AspectRatio, Box, Image } from "components"

const ProjectTile = ({ isMobile, image, link, ratio, rowIndex, video }) => (
  <Box as="li" mt={rowIndex > 0 && [5, 7]}>
    <Link to={link + (isMobile ? "?mobile=true" : "")}>
      <AspectRatio ratio={ratio} bg="background">
        <Image
          image={{
            url: image.url + "&fit=fill&w=600",
            dimensions: {
              height: image.dimensions.height,
              width: image.dimensions.width,
            },
            half: {
              url: image.url + "&fit=fill&w=400",
              dimensions: {
                height: image.dimensions.height / 7,
                width: image.dimensions.width / 7,
              },
            },
            placeholder: {
              url: image.url + "&fit=fill&w=16",
              dimensions: {
                height: image.dimensions.height / 10,
                width: image.dimensions.width / 10,
              },
            },
          }}
          width="100%"
          height="100%"
          sizes="512px"
        />
        {video && video.url && (
          <Box
            position="absolute"
            top={0}
            left={0}
            height="100%"
            width="100%"
            as="video"
            playsInline
            autoPlay
            muted
            loop
            style={{ objectFit: "cover" }}
          >
            <source src={video.url} type="video/mp4" />
            Your browser does not support the video tag.
          </Box>
        )}
      </AspectRatio>
    </Link>
  </Box>
)

function Columns({
  COLUMN_SPEED,
  paddingBottom,
  activeColumns,
  activeCategory,
  isMobileProp,
}) {
  //Parallax Properties
  const { scrollYProgress } = useViewportScroll()
  const y = useTransform(scrollYProgress, value => value * -COLUMN_SPEED)
  const y2 = useTransform(scrollYProgress, value => (value * -COLUMN_SPEED) / 2)
  const y4 = useTransform(
    scrollYProgress,
    value => (value * -COLUMN_SPEED * 4) / 5
  )

  const isMobile = isMobileProp || activeCategory === "Motion"

  return (
    <>
      {/* Column 1 */}
      <AnimatePresence>
        <motion.div
          variants={Column1}
          initial="initial"
          animate="visible"
          exit="exit"
          key={activeCategory + "activeColumn1"}
          style={{ position: "relative", minHeight: "100%" }}
        >
          <Box
            as={motion.ul}
            m={0}
            p={0}
            css={
              isMobile
                ? {
                    listStyle: "none",
                  }
                : {
                    position: "absolute",
                    top: 0,
                    left: 16,
                    right: 16,
                    bottom: 0,
                    listStyle: "none",
                  }
            }
            style={{
              paddingBottom: isMobile ? paddingBottom.mobile : 0,
              y: isMobile ? 0 : y,
            }}
          >
            {!isMobile &&
              activeColumns.desktop[0].projects.map((project, index) => {
                return (
                  <ProjectTile
                    isMobile={isMobile}
                    ratio={
                      project.image.dimensions.width +
                      ":" +
                      project.image.dimensions.height
                    }
                    image={project.image}
                    link={"/project/" + project.project.uid + "/"}
                    rowIndex={index > 0 ? 1 : 0}
                    video={project.video}
                    key={
                      activeCategory +
                      project.image.url +
                      "column1" +
                      "tile" +
                      index
                    }
                  />
                )
              })}
            {isMobile &&
              activeColumns.mobile[0].projects.map((project, index) => {
                return (
                  <ProjectTile
                    isMobile={isMobile}
                    ratio={
                      project.image.dimensions.width +
                      ":" +
                      project.image.dimensions.height
                    }
                    image={project.image}
                    link={"/project/" + project.project.uid + "/"}
                    rowIndex={index > 0 ? 1 : 0}
                    video={project.video}
                    key={
                      activeCategory +
                      project.image.url +
                      "column1" +
                      "tile" +
                      index
                    }
                  />
                )
              })}
          </Box>
        </motion.div>
      </AnimatePresence>

      {/* Column 2 */}
      <AnimatePresence>
        <motion.div
          variants={Column2}
          initial="initial"
          animate="visible"
          exit="exit"
          key={activeCategory + "activeColumn2"}
          style={{ position: "relative", minHeight: "100%" }}
        >
          <Box
            as={motion.ul}
            m={0}
            p={0}
            css={{
              position: "absolute",
              top: 0,
              left: 16,
              right: 16,
              bottom: 0,
              listStyle: "none",
            }}
            style={{
              y: isMobile ? y2 : y2,
            }}
          >
            {!isMobile &&
              activeColumns.desktop[1].projects.map((project, index) => {
                return (
                  <ProjectTile
                    isMobile={isMobile}
                    ratio={
                      project.image.dimensions.width +
                      ":" +
                      project.image.dimensions.height
                    }
                    image={project.image}
                    link={"/project/" + project.project.uid + "/"}
                    rowIndex={index > 0 ? 1 : 0}
                    video={project.video}
                    key={
                      activeCategory +
                      project.image.url +
                      "column1" +
                      "tile" +
                      index
                    }
                  />
                )
              })}
            {isMobile &&
              activeColumns.mobile[1].projects.map((project, index) => {
                return (
                  <ProjectTile
                    isMobile={isMobile}
                    ratio={
                      project.image.dimensions.width +
                      ":" +
                      project.image.dimensions.height
                    }
                    image={project.image}
                    link={"/project/" + project.project.uid + "/"}
                    rowIndex={index > 0 ? 1 : 0}
                    video={project.video}
                    key={
                      activeCategory +
                      project.image.url +
                      "column1" +
                      "tile" +
                      index
                    }
                  />
                )
              })}
          </Box>
        </motion.div>
      </AnimatePresence>

      {/* Column 3 */}
      {!isMobile && (
        <AnimatePresence>
          <motion.div
            variants={Column3}
            initial="initial"
            animate="visible"
            exit="exit"
            key={activeCategory + "activeColumn3"}
          >
            <Box
              as="ul"
              m={0}
              p={0}
              css={{ listStyle: "none" }}
              style={{ paddingBottom: paddingBottom.desktop }}
            >
              {activeColumns.desktop[2].projects.map((project, index) => {
                return (
                  <ProjectTile
                    isMobile={isMobile}
                    ratio={
                      project.image.dimensions.width +
                      ":" +
                      project.image.dimensions.height
                    }
                    image={project.image}
                    link={"/project/" + project.project.uid + "/"}
                    rowIndex={index > 0 ? 1 : 0}
                    video={project.video}
                    key={
                      activeCategory +
                      project.image.url +
                      "column2" +
                      "tile" +
                      index
                    }
                  />
                )
              })}
            </Box>
          </motion.div>
        </AnimatePresence>
      )}

      {/* Column 4 */}
      {!isMobile && (
        <AnimatePresence>
          <motion.div
            variants={Column4}
            initial="initial"
            animate="visible"
            exit="exit"
            key={activeCategory + "activeColumn4"}
            style={{ position: "relative", minHeight: "100%" }}
          >
            <Box
              as={motion.ul}
              m={0}
              p={0}
              css={{
                position: "absolute",
                top: 0,
                left: 16,
                right: 16,
                bottom: 0,
                listStyle: "none",
              }}
              style={{ y: y4 }}
            >
              {activeColumns.desktop[3].projects.map((project, index) => {
                return (
                  <ProjectTile
                    isMobile={isMobile}
                    ratio={
                      project.image.dimensions.width +
                      ":" +
                      project.image.dimensions.height
                    }
                    image={project.image}
                    link={"/project/" + project.project.uid + "/"}
                    rowIndex={index > 0 ? 1 : 0}
                    video={project.video}
                    key={
                      activeCategory +
                      project.image.url +
                      "column3" +
                      "tile" +
                      index
                    }
                  />
                )
              })}
            </Box>
          </motion.div>
        </AnimatePresence>
      )}
    </>
  )
}

export default Columns
